import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import styles from './Question.module.scss';
import type RootStore from '../../../../stores/RootStore';

interface QuestionProps {
  rootStore?: RootStore;
}

const Question: React.FC<QuestionProps> = observer(({ rootStore }) => {
  const quizStore = rootStore?.quizStore;
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    setFadeOut(true);
    const timer = setTimeout(() => {
      setFadeOut(false);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [quizStore?.currentQuestionId]);

  // If quizStore exists, index questions from 0, otherwise default to first question (index 0)
  const currentQuestionIndex =
    quizStore != null
      ? quizStore.questions.findIndex(
          (q) => q.id === quizStore.currentQuestionId,
        )
      : 1;

  const currentQuestion = quizStore?.questions[currentQuestionIndex];

  return (
    <div className={styles.container} aria-live="assertive">
      <span className={`${fadeOut ? styles.fadeOut : styles.questionText}`}>
        {currentQuestion?.text} {currentQuestion?.description}
      </span>
      {currentQuestion?.description != null && (
        <span
          className={`${fadeOut ? styles.fadeOut : styles.questionText}`}
        ></span>
      )}
    </div>
  );
});

export default inject('rootStore')(Question);
