import React from 'react';
import styles from './ExploreOtherRoles.module.scss';
import { Link } from 'react-router-dom';
import rightArrow from '../../../../assets/icons/SingleRightArrow.svg';
const ExploreOtherRoles: React.FC = () => {
  return (
    <div id={styles.foundation}>
        <Link className={styles.container} to='/explore'>
            <h3>Explore other tech roles</h3>
            <img src={rightArrow} alt='Right arrow image'/>
        </Link>
    </div>
  );
};

export default ExploreOtherRoles;
