import type { ReactNode, FC } from 'react';

import styles from './RolesInnerLayout1.module.scss';

interface RolesInnerLayout1Props {
  children: ReactNode;
}

const RolesInnerLayout1: FC<RolesInnerLayout1Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default RolesInnerLayout1;
