import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import { observer, inject } from 'mobx-react';

import type RootStore from '../../stores/RootStore';
import styles from './WhyTechnius.module.scss';

interface WhyTechniusProps {
  rootStore?: RootStore;
}

const WhyTechnius: React.FC<WhyTechniusProps> = observer(
  ({ rootStore }) => {
    const [terms, setTerms] = useState('');

    useEffect(() => {
      void fetch('/whyTech.md')
        .then(async (res) => await res.text())
        .then((text) => {
          setTerms(text);
        });
    }, []);

    return (
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <h2 className={styles.title}>Why Technius</h2>
          <Markdown>{terms}</Markdown>
        </div>
      </div>
    );
  },
);

export default inject('rootStore')(WhyTechnius);
