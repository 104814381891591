import styles from './Footer.module.scss';
import MTLogo from '../../assets/images/Footer/MT-logo-stacked-colour-transparent.svg';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import MRlogo from '../../assets/images/Footer/MRlogo.png';
export default function Footer(): React.JSX.Element {
  return (
    <footer className={styles.foundation}>
      <nav>
        <div className={styles.container}>
          <div id={styles.box1}>
            <img src={MTLogo} alt='Mission Techinius logo'/>
            <div>
              <Link to="/quiz" className={styles.box1Links}>
                TAKE THE QUIZ
              </Link>
              <Link to="/explore" className={styles.box1Links}>
                EXPLORE TECH ROLES
              </Link>
              <Link to="/why-technius" className={styles.box1Links}>
                WHY TECHNIUS
              </Link>
            </div>
          </div>

          <div id={styles.box2}>
            <p id={styles.box2heading}>UNLOCK YOUR TECH FUTURE!</p>
            <p id={styles.box2description}>
              We&apos;ve got answers! Hit us up for a quick chat.
            </p>
            <a
              href="https://www.missionreadyhq.com/fb-book-a-call/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Book a call</Button>
            </a>
          </div>
          <div id={styles.box3}>
            <p id={styles.box3heading}>
              Mission Technius is brought to you by:{' '}
            </p>
            <img alt="Mission Ready HQ Logo" src={MRlogo} />
            <p className={styles.box3text}>
              <a href="tel:0800 005 875">0800 005 875</a>
            </p>
            <a
              href="https://www.missionreadyhq.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.missionreadyhq.com
            </a>
            <br />
            <a
              href="mailto:hello@missionreadyhq.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@missionreadyhq.com
            </a>
            <p className={styles.box3P}>With support from:</p>
            <img
              src="https://www.aucklandcouncil.govt.nz/_layouts/15/ACWeb/images/ac-logo-te-reo.svg"
              alt='Auckland council logo'
            />
            <a
              href="https://www.aucklandcouncil.govt.nz/Pages/default.aspx"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.box3lastAtag}
            >
              www.aucklandcouncil.govt.nz
            </a>
          </div>
        </div>
        <hr id={styles.footNavHr} />
        <p className={styles.footerLine}>
          © 2023 Mission Ready | <Link to="/privacy"> Privacy Policy </Link> |
          <br id={styles.footNavBr} />
          <Link to="/terms"> Terms & Conditions </Link>
        </p>
      </nav>
    </footer>
  );
}
