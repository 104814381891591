import React from 'react';
import { observer, inject } from 'mobx-react';

import type RootStore from '../../../../stores/RootStore';
import { type ROLE_ID } from '../../../../constants';
import CheckCircle from '../../../../assets/images/ExploreCareers/iconCheckCircle.svg';
// eslint-disable-next-line max-len
import { ReactComponent as DoubleArrowRightPurple } from '../../../../assets/icons/DoubleArrowRightPurple.svg';

import CalendarImg from '../../../../assets/images/ExploreCareers/TechRoles/calendar.svg';
import ClipboardImg from '../../../../assets/images/ExploreCareers/TechRoles/clipboard.svg';
import CodeScreenImg from '../../../../assets/images/ExploreCareers/TechRoles/codescreen.svg';
import GraphImg from '../../../../assets/images/ExploreCareers/TechRoles/graph.svg';
import MeetingImg from '../../../../assets/images/ExploreCareers/TechRoles/meeting.svg';
import MegaphoneImg from '../../../../assets/images/ExploreCareers/TechRoles/megaphone.svg';
import PaletteImg from '../../../../assets/images/ExploreCareers/TechRoles/palette.svg';
import SupportImg from '../../../../assets/images/ExploreCareers/TechRoles/support.svg';

import styles from './RoleCard.module.scss';
import { Link } from 'react-router-dom';

interface RoleCardProps {
  rootStore?: RootStore;
  roleId: ROLE_ID;
}

const roleIdImageMap = {
  0: null,
  1: CodeScreenImg,
  2: SupportImg,
  3: GraphImg,
  4: ClipboardImg,
  5: CalendarImg,
  6: MeetingImg,
  7: PaletteImg,
  8: MegaphoneImg,
};

const RoleCard: React.FC<RoleCardProps> = observer(({ rootStore, roleId }) => {
  const resultStore = rootStore?.resultStore;
  const cardRole = resultStore?.roleInfo.find((role) => role.roleId === roleId);

  if (cardRole === undefined || cardRole === null) {
    return null;
  }
  const cardRoleImage = roleIdImageMap[cardRole?.roleId] ?? null;

  return (
    <div className={styles.container} key={roleId}>
      {cardRoleImage != null && (
        <img
          className={styles.roleImage}
          src={cardRoleImage}
          alt={cardRole.title}
        />
      )}
      <div className={styles.title}>{cardRole?.title}</div>
      <div className={styles.values}>
        {cardRole?.values.map((elem, innerIndex) => {
          return (
            <div key={innerIndex}>
              <img src={CheckCircle} />
              {elem}
            </div>
          );
        })}
      </div>
      <p className={styles.description}>{cardRole?.description}</p>
      <Link className={styles.learnMore} to={cardRole?.link}>
        <div>Read more</div>
        <DoubleArrowRightPurple className={styles.learnmoreImg} />
      </Link>
    </div>
  );
});

export default inject('rootStore')(RoleCard);
