import { makeAutoObservable } from 'mobx';

import questionData from '../config/questionData.json';
import type RootStore from './RootStore';
import { type SelectOption, type IQuestion } from '../types/QuizData';

class QuizStore {
  questions: IQuestion[] = questionData.questions as IQuestion[];
  currentQuestionId = this.questions[0].id;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
    this.loadSelectedAnsFromLocal();
    this.loadCurrentQuestionIdFromLocal();
  }

  setCurrentQuestionId(id: string): void {
    this.currentQuestionId = id;
  }

  goBackOneQuestion(): void {
    const currentQuestionIndex = this.questions.findIndex(
      (q) => q.id === this.currentQuestionId,
    );
    if (currentQuestionIndex > 0) {
      this.currentQuestionId = this.questions[currentQuestionIndex - 1].id;
      this.saveCurrentQuestionIdToLocal();
    }
  }

  goForwardOneQuestion(): void {
    const currentQuestionIndex = this.questions.findIndex(
      (q) => q.id === this.currentQuestionId,
    );
    if (currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionId = this.questions[currentQuestionIndex + 1].id;
      this.saveCurrentQuestionIdToLocal();
    }
  }

  get currentQuestion(): IQuestion | null {
    if (this.currentQuestionId === null) {
      return null;
    }

    const foundQuestion = this.questions.find(
      (q) => q.id === this.currentQuestionId,
    );

    if (foundQuestion === undefined) {
      return null;
    }

    return foundQuestion;
  }

  selectOption(questionId: string, optionId: string | string[]): boolean {
    const question = this.questions.find((q) => q.id === questionId);
    if (question == null) return false;
    if (Array.isArray(optionId)) {
      if (question.questionType !== 'multiple') return false;
      if (!Array.isArray(question.selectedOptionId)) {
        question.selectedOptionId = [];
      }

      // Update selectedOptionId array based on whether the option was already selected
      question.selectedOptionId = optionId;
    } else {
      if (question.questionType !== 'single') return false;
      question.selectedOptionId = optionId;
      this.goForwardOneQuestion();
    }

    this.saveSelectedAnsToLocal();
    return true;
  }

  loadSelectedAnsFromLocal(): void {
    const savedSelectedAnswers = localStorage.getItem('selectedAnswers');
    if (savedSelectedAnswers !== null) {
      const parsedAnswers: SelectOption[] = JSON.parse(savedSelectedAnswers);
      parsedAnswers.forEach(({ id, selectedOptionId }) => {
        const question = this.questions.find((q) => q.id === id);
        if (question != null) {
          question.selectedOptionId = selectedOptionId;
        }
      });
    }
  }

  saveSelectedAnsToLocal(): void {
    // Create an array of objects containing only id and selectedOptionId for each question
    const selectedAnswers = this.questions.map((q) => ({
      id: q.id,
      selectedOptionId: q.selectedOptionId,
    }));
    localStorage.setItem('selectedAnswers', JSON.stringify(selectedAnswers));
  }

  loadCurrentQuestionIdFromLocal(): void {
    const savedCurrentQuestionId = localStorage.getItem('currentQuestionId');
    if (savedCurrentQuestionId !== null) {
      this.currentQuestionId = savedCurrentQuestionId;
    }
  }

  saveCurrentQuestionIdToLocal(): void {
    localStorage.setItem('currentQuestionId', this.currentQuestionId);
  }

  clearStore(): void {
    console.log('Clearing user progress');
    this.questions = questionData.questions as IQuestion[];
    this.currentQuestionId = this.questions[0].id;
    localStorage.removeItem('selectedAnswers');
    localStorage.removeItem('currentQuestionId');
  }
}
export default QuizStore;
