import React from 'react';
import { observer, inject } from 'mobx-react';
import ReactGA from 'react-ga4';

import type RootStore from '../../../../stores/RootStore';
import { ReactComponent as DoubleRightArrow } from '../../../../assets/icons/DoubleRightArrow.svg';
import styles from './DoneButton.module.scss';

interface DoneButtonProps {
  rootStore?: RootStore;
  evaluateQuiz: () => void;
}

const DoneButton: React.FC<DoneButtonProps> = observer(
  ({ rootStore, evaluateQuiz }) => {
    const quizStore = rootStore?.quizStore;
    const currentQuestionIndex =
      quizStore != null
        ? quizStore.questions.findIndex(
            (q) => q.id === quizStore.currentQuestionId,
          )
        : 1;
    const lastQuestionIndex = (quizStore?.questions?.length ?? 0) - 1;
    const isLastQuestion = currentQuestionIndex === lastQuestionIndex;

    const handleButtonClick = (): void => {
      // Track the event with ReactGA
      ReactGA.event({
        category: 'Quiz Pages',
        action: 'Click',
        label: isLastQuestion ? 'Done Button' : 'Next Button',
      });

      // Existing button logic
      if (quizStore != null) {
        if (isLastQuestion) {
          evaluateQuiz();
        }
        quizStore.goForwardOneQuestion();
      }
    };

    return (
      <div className={styles.container}>
        <button onClick={handleButtonClick}>
          {isLastQuestion ? (
            'Done !'
          ) : (
            <>
              Next <DoubleRightArrow className={styles.Arrow} />
            </>
          )}
        </button>
      </div>
    );
  },
);

export default inject('rootStore')(DoneButton);
