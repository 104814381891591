/* eslint-disable max-len */
import { type ReactNode } from 'react';
import styles from './Button.module.scss';

/**
 * Properties for Button component.
 *
 * @prop {ReactNode} children - Content to be displayed inside the button.
 * @prop {'purple' | 'blue' | 'navy' | 'gradient'} [type='purple'] - Determines the button's color scheme.
 * @prop {boolean} [minWidth=false] - If true, the button will have a minimum width.
 */
interface ButtonProps {
  children: ReactNode;
  type?: 'purple' | 'blue' | 'navy' | 'gradient';
  minWidth?: boolean;
  onClick?: () => void;
}

/**
 * Custom Button component that can be styled based on the provided props.
 *
 * @param {ButtonProps} props - The props for the Button component.
 * @returns The Button component.
 *
 * @example
 * // Purple button with no minimum width
 * <Button type="purple">Click Me</Button>
 *
 * @example
 * // Blue button with minimum width
 * <Button type="blue" minWidth>Click Me</Button>
 *
 * @example
 * // Gradient button filling the width of its container
 * <Button type="gradient">Click Me</Button>
 */
const Button: React.FC<ButtonProps> = ({
  children,
  type = 'purple',
  minWidth = false,
  onClick,
}) => {
  const buttonClass = `${styles.button} ${styles[type]} ${
    minWidth ? styles.minWidth : ''
  } ${type === 'gradient' ? styles.fullWidth : ''}`;

  const isGradient = type === 'gradient';

  return isGradient ? (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <button className={buttonClass} onClick={onClick}>{children}</button>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <button className={buttonClass} onClick={onClick}>{children}</button>
    </div>
  );
};

export default Button;
