import React from 'react';
import styles from './Why.module.scss';
import RolesLayout from '../../../../../layouts/Roles/RolesLayout';
import RolesInnerLayout1 from '../../../../../layouts/Roles/innerLayout1/RolesInnerLayout1';
import RolesInnerLayout2 from '../../../../../layouts/Roles/innerLayout2/RolesInnerLayout2';
import type { RoleDataWhy } from '../../../../../types/RolePagesData';
import roleId4Img from '../../../../../assets/images/Roles/SoftwareTester/image3.jpg';
import roleId1Img from '../../../../../assets/images/Roles/SoftwareDeveloper/image3.jpg';
import roleId7Img from '../../../../../assets/images/Roles/SoftwareDesigner/image3.jpg';
import roleId6Img from '../../../../../assets/images/Roles/BusinessAnalyst/image3.jpg';
import roleId2Img from '../../../../../assets/images/Roles/Support&Admin/image3.jpg';
import roleId8Img from '../../../../../assets/images/Roles/MarketingAndSales/image3.jpg';
import roleId5Img from '../../../../../assets/images/Roles/ProjectManager/image3.jpg';
import roleId3Img from '../../../../../assets/images/Roles/DataAnalyst/image3.jpg';
import LinkParser from '../../../../../components/LinkParser/LinkParser';

const Why: React.FC<{ roleData: RoleDataWhy; roleId: number }> = ({roleData,roleId}) => {
  const roleImgs = [
    { id: 4, img: roleId4Img },
    { id: 1, img: roleId1Img },
    { id: 7, img: roleId7Img },
    { id: 6, img: roleId6Img },
    { id: 2, img: roleId2Img },
    { id: 8, img: roleId8Img },
    { id: 5, img: roleId5Img },
    { id: 3, img: roleId3Img },
  ];
  const roleImg = roleImgs.find((elem) => elem.id === roleId);
  return (
    <div id={styles.foundation}>
      <RolesLayout flexType="wrap">
        <RolesInnerLayout1>
          <img src={roleImg?.img} alt={roleData.image} />
        </RolesInnerLayout1>
        <RolesInnerLayout2>
          <div className={styles.container}>
            <h3>{roleData.title}</h3>
            {roleData.paragraphs.map((elem, index) => {
              return <LinkParser inputString={elem} key={index}></LinkParser>;
            })}
          </div>
        </RolesInnerLayout2>
      </RolesLayout>
    </div>
  );
};

export default Why;
