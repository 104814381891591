import loadingSpinner from '../../assets/icons/loading-spinner.gif';
import styles from './Loading.module.scss';

const Loading: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src={loadingSpinner} alt="Loading..." />
    </div>
  );
};

export default Loading;
