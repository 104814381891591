import React from 'react';
import styles from './DiscoverCareers.module.scss';
import peerWorkShadow from '../../../../assets/images/Home/DiscoverCareers/peerWorkShadow.jpg';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
const DiscoverCareers: React.FC = () => {
  return (
    <div id={styles.foundation}>
      {/* <img src={backgroundImg} /> */}
      <div className={styles.backgroundImgCover} />
      <div id={styles.container1}>
        <h1>
          BUILD YOUR
          <br />
          <div>TECH FUTURE</div>
        </h1>
        <p>Dive In & Discover Your Path</p>
        <div id={styles.mobileContainer2innerContainer1}>
          <h2>Tech: Belong. Be You!</h2>
          <p>
            Check out our video above to see why Kiwis are buzzing about Tech careers.
            Take our quiz and discover how you might fit in the tech industry!
            Or explore the most popular tech careers and find your calling!
          </p>
        </div>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/V-eyzuoSiW4?si=ICccClv7MfOQC9hl"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write;
          encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen // Change allowfullscreen to allowFullScreen
        ></iframe>
        <img src={peerWorkShadow} className={styles.peerWorkShadowImg} alt='Video shadow image'/>
      </div>
      <div id={styles.container2}>
        <div id={styles.container2innerContainer1}>
          <h2>Tech: Belong. Be You!</h2>
          <p>
            Check out our video to see why Kiwis are buzzing about Tech careers.
            Take our quiz and discover how you might fit in the tech industry!
            Or explore the most popular tech careers and find your calling!
          </p>
        </div>
        <div id={styles.container2innerContainer2}>
          <Link to="/quiz" id={styles.button1}>
            <Button type="purple">Take the quiz</Button>
          </Link>
          <Link to="/explore">
            <Button type="blue">Explore tech roles</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DiscoverCareers;
