import React from 'react';

import ExploreTechRoles from './components/ExporeTechRoles/ExporeTechRoles';
import TypeOfTechRoles from './components/TypeOfTechRoles/TypeOfTechRoles';

import styles from './ExploreCareers.module.scss';

import backgroundComp1 from '../../assets/images/ExploreCareers/backgroundComp1.jpg';
import backgroundComp2 from '../../assets/images/ExploreCareers/backgroundComp2.jpg';
import backgroundComp3 from '../../assets/images/ExploreCareers/backgroundComp3.jpg';

const ExploreCareers: React.FC = () => {
  return (
    <div id={styles.foundation}>
      <ExploreTechRoles />
      <TypeOfTechRoles />
      <div id={styles.container}>
        <img src={backgroundComp1} id={styles.bgComp1} />
        <img src={backgroundComp2} id={styles.bgComp2} />
      </div>
      <img src={backgroundComp3} id={styles.bgComp3} />
    </div>
  );
};

export default ExploreCareers;
