import axios, { type AxiosResponse } from 'axios';

import { API_ENDPOINTS } from '../utils/constants';
import { type QuizAnswerData } from '../types/QuizData';
import { type ApiResponse, type GenericPostResponse } from '../types';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

async function apiRequest<T>(
  request: Promise<AxiosResponse<T>>,
): Promise<ApiResponse<T>> {
  try {
    const response = await request;
    return { data: response.data, error: null };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const statusCode = error.response?.status ?? 500;
      const message = error.response?.data.message ?? 'An error occurred';
      // Handling rate limiting specific error
      if (statusCode === 429) {
        return {
          data: null,
          error: 'Rate limit exceeded. Please try again later.',
        };
      }
      return {
        data: null,
        error: `API Error: ${message}, Status Code: ${statusCode}`,
      };
    } else {
      return { data: null, error: 'An unknown error occurred' };
    }
  }
}

export async function sendQuizResultEmail(
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any,
): Promise<ApiResponse<GenericPostResponse>> {
  return await apiRequest(
    axiosInstance.post(API_ENDPOINTS.QUIZ_RESULT_EMAIL, payload),
  );
}

export async function evaluateAnswersData(
  payload: QuizAnswerData,
): Promise<ApiResponse<GenericPostResponse>> {
  return await apiRequest(
    axiosInstance.post(API_ENDPOINTS.QUIZ_EVALUATE, payload),
  );
}

export async function sentPreLaunchInterest(
  emailId: string,
): Promise<ApiResponse<GenericPostResponse>> {
  return await apiRequest(
    axiosInstance.post(API_ENDPOINTS.PRE_LAUNCH_INTEREST, { email: emailId }),
  );
}

