import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Header.module.scss';

import { ReactComponent as TechniusLogo } from '../../assets/icons/TechniusLogo.svg';
import ArrowDown from '../../assets/icons/arrowDown.svg';
import hamburgerMenu from '../../assets/icons/HamBurgerMenu.svg';
import closeHamburgerMenu from '../../assets/icons/x.svg';
import TechniusSymbol from '../../assets/icons/TechniusSymbol.svg';
import DoubleArrowRightImg from '../../assets/icons/DoubleArrowRight.svg';

export default function Header(): React.JSX.Element {
  const [mainNavOpened, setMainNavOpened] = useState(false);
  const [hamburgerMenuOpened, setHamburgerMenuOpened] = useState(false);
  const closeMobileMenu = (): void => {
    setHamburgerMenuOpened(false);
  };

  return (
    <header className={styles.container}>
      {/* Note: We have two versions of the navbar code based on device */}
      <nav id={styles.MainNavigation}>
        <div>
          <NavLink to="/home" aria-label="Home">
            <TechniusLogo
              className={styles.techniusLogo}
              role="img"
              aria-label="Technius Logo"
            />
          </NavLink>
        </div>
        <div className={styles.icons}>
          <NavLink
            to="/quiz"
            className={styles.navLink}
            aria-label="Go to Quiz"
          >
            <span>TAKE THE QUIZ</span>
          </NavLink>
          {mainNavOpened ? (
            <div
              id={styles.NavBackground}
              onMouseEnter={() => {
                setMainNavOpened(false);
              }}
            />
          ) : null}

          <div
            id={styles.selectForTechRolesContainer}
            onMouseEnter={() => {
              setMainNavOpened(true);
            }}
          >
            <NavLink
              id={styles.selectForTechRoles}
              onMouseEnter={() => {
                setMainNavOpened(true);
              }}
              to="/explore"
            >
              <div
                onMouseEnter={() => {
                  setMainNavOpened(true);
                }}
              >
                EXPLORE TECH ROLES{' '}
              </div>
              <img
                src={ArrowDown}
                onMouseEnter={() => {
                  setMainNavOpened(true);
                }}
                alt="Arrow down icon"
              />
            </NavLink>
            <div
              className={styles.selectForTechRolesOptionsContainer}
              id={
                mainNavOpened
                  ? styles.selectForTechRolesOptionsOpened
                  : styles.selectForTechRolesOptionsClosed
              }
            >
              <NavLink
                to="/roles/SoftwareDesigner"
                className={styles.selectForTechRolesOptions}
              >
                <div>Software Designer</div>
              </NavLink>
              <NavLink
                to="/roles/SoftwareDeveloper"
                className={styles.selectForTechRolesOptions}
              >
                <div>Software Developer</div>
              </NavLink>
              <NavLink
                to="/roles/SupportAndAdmin"
                className={styles.selectForTechRolesOptions}
              >
                <div>Support & Admin</div>
              </NavLink>
              <NavLink
                to="/roles/MarketingAndSales"
                className={styles.selectForTechRolesOptions}
              >
                <div>Marketing & Sales</div>
              </NavLink>
              <NavLink
                to="/roles/BusinessAnalyst"
                className={styles.selectForTechRolesOptions}
              >
                <div>Business Analyst</div>
              </NavLink>
              <NavLink
                to="/roles/ProjectManager"
                className={styles.selectForTechRolesOptions}
              >
                <div>Project Manager</div>
              </NavLink>
              <NavLink
                to="/roles/SoftwareTester"
                className={styles.selectForTechRolesOptions}
              >
                <div>Software Tester</div>
              </NavLink>
              <NavLink
                to="/roles/DataAnalyst"
                className={styles.selectForTechRolesOptions}
              >
                <div>Data Analyst</div>
              </NavLink>
            </div>
          </div>
          <NavLink
            to="/schools"
            className={styles.navLink}
            aria-label="Technius for Schools"
          >
            <span>SCHOOLS</span>
          </NavLink>
        </div>
      </nav>
      <nav id={styles.MobileNavigation}>
        <div>
          <NavLink to="/home" aria-label="Home">
            <img
              id={styles.techniusSymbol}
              role="img"
              aria-label="Technius Logo"
              src={TechniusSymbol}
            />
          </NavLink>
        </div>
        <img
          id={
            hamburgerMenuOpened
              ? styles.hamburgerMenu
              : styles.closeHamburgerMenu
          }
          src={hamburgerMenuOpened ? closeHamburgerMenu : hamburgerMenu}
          onClick={() => {
            setHamburgerMenuOpened(!hamburgerMenuOpened);
          }}
          alt="Mobile hamburger menu icon"
        />
        <div
          className={styles.mobileNavOptionsContainers}
          id={
            hamburgerMenuOpened
              ? styles.mobileNavOptionsContainer1
              : styles.mobileNavOptionsContainer2
          }
        >
          <div id={styles.mobileNavOptionsInnerContainer}>
            <NavLink
              to="/quiz"
              id={styles.mobileNavOptionTakeQuiz}
              className={styles.mobileNavOptions}
              onClick={closeMobileMenu}
            >
              <div>TAKE THE QUIZ</div>
              <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
            </NavLink>
            <NavLink
              to="/explore"
              id={styles.mobileNavOptionExploreRoles}
              className={styles.mobileNavOptions}
              onClick={closeMobileMenu}
            >
              <div>EXPLORE TECH ROLES</div>
              <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
            </NavLink>
            <div id={styles.mobileNavOptionsBox}>
              <NavLink
                to="/roles/SoftwareDesigner"
                className={styles.mobileNavOptions}
                onClick={closeMobileMenu}
              >
                <div>Software Designer</div>
                <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
              </NavLink>
              <NavLink
                to="/roles/SoftwareDeveloper"
                className={styles.mobileNavOptions}
                onClick={closeMobileMenu}
              >
                <div>Software Developer</div>
                <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
              </NavLink>
              <NavLink
                to="/roles/SupportAndAdmin"
                className={styles.mobileNavOptions}
                onClick={closeMobileMenu}
              >
                <div>Support & Admin</div>
                <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
              </NavLink>
              <NavLink
                to="/roles/MarketingAndSales"
                className={styles.mobileNavOptions}
                onClick={closeMobileMenu}
              >
                <div>Marketing & Sales</div>
                <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
              </NavLink>
              <NavLink
                to="/roles/BusinessAnalyst"
                className={styles.mobileNavOptions}
                onClick={closeMobileMenu}
              >
                <div>Business Analyst</div>
                <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
              </NavLink>
              <NavLink
                to="/roles/ProjectManager"
                className={styles.mobileNavOptions}
                onClick={closeMobileMenu}
              >
                <div>Project Manager</div>
                <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
              </NavLink>
              <NavLink
                to="/roles/SoftwareTester"
                className={styles.mobileNavOptions}
                onClick={closeMobileMenu}
              >
                <div>Software Tester</div>
                <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
              </NavLink>
              <NavLink
                to="/roles/DataAnalyst"
                className={styles.mobileNavOptions}
                onClick={closeMobileMenu}
              >
                <div>Data Analyst</div>
                <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
              </NavLink>
            </div>
            <NavLink
              to="/schools"
              id={styles.mobileNavOptionExploreRoles}
              className={styles.mobileNavOptions}
              onClick={closeMobileMenu}
            >
              <div>SCHOOLS</div>
              <img src={DoubleArrowRightImg} alt="Double arrow right icon" />
            </NavLink>
          </div>
        </div>
      </nav>
    </header>
  );
}
