import React from 'react';
import styles from './HowToGetStarted.module.scss';
import patternedImg from '../../../../assets/images/Roles/patternedLines.svg';
import doubleArrowRightImg from '../../../../assets/images/Roles/DoubleArrowRight.svg';
import LinkParser from '../../../../components/LinkParser/LinkParser';

interface Advice {
  paragraphs?: string[];
  links: string[];
}
interface RoleData1 {
  heading: string;
  paragraphs: string[];
  advice?: Advice[];
}
interface RoleData2 {
  heading: string;
  paragraphs: string[];
}
interface RolesProps {
  roleData1: RoleData1;
  roleData2: RoleData2;
}
const HowToGetStarted: React.FC<RolesProps> = ({ roleData1, roleData2 }) => {
  return (
    <div id={styles.foundation}>
      <div className={styles.container}>
        <img src={patternedImg} alt="Patterned image" />
        <div className={styles.innerContainer}>
          <h2>{roleData1.heading}</h2>
          {roleData1.paragraphs?.map((elem, index) => {
            return (
              <LinkParser
                inputString={elem}
                tagType="Link"
                key={index}
              ></LinkParser>
            );
          })}
          {roleData1.advice?.map((element, index) => {
            return (
              <div key={index}>
                {element.paragraphs?.map((elem, innerIndex) => {
                  return (
                    <LinkParser
                      inputString={elem}
                      key={innerIndex}
                      tagType='Link'
                    ></LinkParser>
                  );
                })}

                <div className={styles.boxs}>
                  <img src={doubleArrowRightImg} alt="Douvle arrow icon" />
                  <div>
                    {element.links.map((elem, innerIndex) => {
                      return (
                        <LinkParser
                          key={innerIndex}
                          inputString={elem}
                          tagType="Link"
                        ></LinkParser>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HowToGetStarted;
