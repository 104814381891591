import { makeAutoObservable } from 'mobx';

import rolesData from '../config/roleData.json';
import { type EvaluationResponse } from '../types';
import { type Role } from '../types/RoleData';
// eslint-disable-next-line max-len
import {
  EXTRA_ROLES_ROLE_ID,
  ROLE_ID,
  SUGGESTION_TYPE_ID,
  SuggestionMessages,
} from '../constants/index';

class QuizResultStore {
  result: EvaluationResponse = {
    mainRoles: [],
    extraRoles: [],
  };

  defaultRoles: number[] = [2, 7, 1];

  roleInfo: Role[] = rolesData.roles as Role[];
  suggestionMessage: string = '';
  suggestionType: SUGGESTION_TYPE_ID = SUGGESTION_TYPE_ID.HasNoResult;

  constructor() {
    makeAutoObservable(this);
    // this.setResult({
    //   mainRoles: [ 0 ],
    //   extraRoles: [ 0 ],
    // });
  }

  evaluateSuggestions(result: EvaluationResponse): void {
    const { mainRoles, extraRoles } = result;
    const noMainRoles =
      mainRoles.length === 0 ||
      (mainRoles.length === 1 && mainRoles[0] === ROLE_ID.None);
    const noExtraRoles =
      extraRoles.length === 0 ||
      (extraRoles.length === 1 && extraRoles[0] === EXTRA_ROLES_ROLE_ID.None);

    if (noMainRoles && noExtraRoles) {
      this.setSuggestion(SUGGESTION_TYPE_ID.HasNoResult);
    } else if (noMainRoles) {
      this.setSuggestion(SUGGESTION_TYPE_ID.HasNoResultHighR);
    } else if (noExtraRoles) {
      this.setSuggestion(SUGGESTION_TYPE_ID.HasResult);
    } else {
      this.setSuggestion(SUGGESTION_TYPE_ID.HasResultHighR);
    }
  }

  setResult(result: EvaluationResponse): void {
    this.result = result;
    this.evaluateSuggestions(this.result);
  }

  setSuggestion(suggestionType: SUGGESTION_TYPE_ID): void {
    this.suggestionMessage = SuggestionMessages[suggestionType];
    this.suggestionType = suggestionType;
  }

  clearResult(): void {
    this.result = {
      mainRoles: [],
      extraRoles: [],
    };
  }
}

export default QuizResultStore;
