import React from 'react';
import HomeLayout from '../../../../layouts/Home/HomeLayout';
import styles from './ExploreCareers.module.scss';
import ArrowRightImg from '../../../../assets/images/Home/ExploreCareers/DoubleArrow.svg';
import GroupWorkImg from '../../../../assets/images/Home/ExploreCareers/GroupWork.jpg';
import bgImg1 from '../../../../assets/images/Home/ExploreCareers/BgImage1.jpg';
import bgImg2 from '../../../../assets/images/Home/ExploreCareers/BgImage2.jpg';
import HomeInnerLayout1 from '../../../../layouts/Home/innerLayout1/HomeInnerLayout1';
import HomeInnerLayout2 from '../../../../layouts/Home/innerLayout2/HomeInnerLayout2';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
const ExploreCareers: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src={bgImg1} className={styles.bgImg1} alt='Corner background image'/>
      <div>
        <HomeLayout flexType="wrap">
          <HomeInnerLayout1>
            <img src={GroupWorkImg} className={styles.GroupWorkImg} alt='Image of group work'/>
          </HomeInnerLayout1>
          <HomeInnerLayout2>
            <div className={styles.innerContainer}>
              <h2>Dive deep!</h2>
              <p className={styles.p1}>
                Explore 8 different tech career categories - salaries, skills, and how to
                land your dream job.
              </p>
              <p className={styles.p2}>
                Start with these 3 top picks, or explore them all!
              </p>
              <div className={styles.rolesContainer}>
                <Link to="/roles/SoftwareDeveloper" className={styles.roles}>
                  <div>Software Developer</div>
                  <img src={ArrowRightImg} alt='Arrow right icon'/>
                </Link>
                <Link to="/roles/SoftwareDesigner" className={styles.roles}>
                  <div>Software Designer</div>
                  <img src={ArrowRightImg} alt='Arrow right icon'/>
                </Link>
                <Link to="/roles/SupportAndAdmin" className={styles.roles}>
                  <div>Support & Admin</div>
                  <img src={ArrowRightImg} alt='Arrow right icon'/>
                </Link>
              </div>
              <div className={styles.buttonContainer}>
                <Link to="/explore">
                  <Button>View more roles</Button>
                </Link>
              </div>
            </div>
          </HomeInnerLayout2>
        </HomeLayout>
      </div>
      <img src={bgImg2} className={styles.bgImg2} alt='Background patterned right side image'/>
    </div>
  );
};

export default ExploreCareers;
