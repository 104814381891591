import React, { useState } from 'react';
import styles from './NotifyOnLaunch.module.scss';
import { sentPreLaunchInterest } from '../../../../services/api';

const NotifyOnLaunch: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const resetForm = (): void => {
    setSubmitted(false);
    setEmail(''); // Optionally reset the email field
    setErrorMsg('');
  };

  const subscribeEmail = async (): Promise<void> => {
    setIsLoading(true);
    setErrorMsg('');
    try {
      const { data, error } = await sentPreLaunchInterest(email);
      if (error != null) {
        setErrorMsg(error);
        return;
      }
      if (data?.message != null) {
        console.log('Email sent successfully!');
        setSubmitted(true);
        setTimeout(resetForm, 8000); // Reset the form after 8 seconds
      } else {
        setErrorMsg('Failed to subscribe due to unexpected issue.');
        console.error('API returned non-201 status:', data);
      }
    } catch (err) {
      console.error('An unknown error occurred:', err);
      setErrorMsg('An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMsg('');
    void subscribeEmail();
  };

  return (
    <div className={styles.container}>
      <h2>We are launching soon</h2>
      {!submitted ? (
        <>
          <p>
            Simply enter your email here and be the first to know when we go
            live.
          </p>
          <div className={styles.formBox}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Your school email address"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? 'Subscribing...' : 'Notify Me!'}
              </button>
            </form>
          </div>
          {errorMsg.length > 0 && <p className={styles.error}>{errorMsg}</p>}
        </>
      ) : (
        <p className={styles.thankYouMessage}>
          Thanks for subscribing! Exciting updates are on the way!
        </p>
      )}
    </div>
  );
};

export default NotifyOnLaunch;
