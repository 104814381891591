import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { routes } from './utils/Routes';
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from './constants';
const router = createBrowserRouter(routes);

function App(): React.JSX.Element {
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
