import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './LinkParser.module.scss';
import CustomBold from '../CustomBold/CustomBold';
//  example to use this comp:
//  <LinkParser inputString='this is a [link](/home)' tagType='Link'/>
//  <LinkParser inputString='this is a [link](http://localhost:3000)' tagType='a'/>

interface ParserProps1 {
  text: string;
  tagType?: string;
}
interface ParserProps2 {
  inputString: string;
  tagType?:"a"|"Link";
}
const Parser: React.FC<ParserProps1> = ({ text, tagType }) => {
  const [tagTypeReturn, setTagTypeReturn] = useState(tagType);
  useEffect(() => {
    if (text.includes('https://') || text.includes('www.')) {
    setTagTypeReturn('a');
  }
  },[]);
  
    const parseText = (): React.ReactNode => {
      const parts = text.split(/(\[.*?\]\(.*?\))/);
      return parts.flatMap((part: string, index: number) => {
        if (part.startsWith('[') && part.endsWith(')')) {
          // It's a link, extract text and href
          const match = /\[(.*?)\]\((.*?)\)/.exec(part);
          const [, linkText, href] = match ?? [];
          if (
            linkText !== undefined &&
            href !== undefined &&
            linkText !== '' &&
            href !== ''
          ) {
            return tagTypeReturn === 'Link' ? (
              <Link key={index} to={href} id={styles.linkParserLink}>
                {linkText}
              </Link>
            ) : (
              <a
                key={index}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                id={styles.linkParserA}
              >
                {linkText}
              </a>
            );
          }
        } else {
          // It's plain text
          return <CustomBold key={index} inputString={part}></CustomBold>;
        }
        return null; // Explicitly return null outside the if condition
      });
    };

  return <p id={styles.linkParserP}>{parseText()}</p>;
};
const LinkParser: React.FC<ParserProps2> = ({ inputString, tagType }) => {
    const [tagData, setTagData] = useState('');
    useEffect(() => {
        tagType===undefined ? setTagData(''): setTagData(tagType);
    },[tagType]);
    
    
  return <Parser text={inputString} tagType={tagData}/>;
};

export default LinkParser;
