import type { ReactNode, FC } from 'react';

import styles from './HomeInnerLayout1.module.scss';

interface HomeInnerLayout1Props {
  children: ReactNode;
}

const HomeInnerLayout1: FC<HomeInnerLayout1Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default HomeInnerLayout1;
