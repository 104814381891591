import React from 'react';
import { observer, inject } from 'mobx-react';

import styles from './BackButton.module.scss';
import type RootStore from '../../../../stores/RootStore';
import { ReactComponent as LeftIcon } from '../../../../assets/images/Quiz/icon-chevron-left.svg';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  rootStore?: RootStore;
  isIntro: boolean;
}

const BackButton: React.FC<BackButtonProps> = observer(
  ({ rootStore, isIntro = false }) => {
    const quizStore = rootStore?.quizStore;

    const handleKeyDown = (event: React.KeyboardEvent): void => {
      // Listen for the Enter or Space key presses
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault(); // Prevent the default action to ensure it behaves as a button
        handleBackClick();
      }
    };

    const handleBackClick = (): void => {
      quizStore?.goBackOneQuestion();
    };

    return (
      <>
        {!isIntro && (
          <div className={styles.container} onClick={handleBackClick}>
            <button
              aria-label="Go back to the previous question"
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
            >
              <LeftIcon></LeftIcon>
            </button>
          </div>
        )}
        {isIntro && (
          <div className={styles.container}>
            <Link to="/home">
              <button
                aria-label="Go back to home page"
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              >
                <LeftIcon></LeftIcon>
              </button>
            </Link>
          </div>
        )}
      </>
    );
  },
);

export default inject('rootStore')(BackButton);
