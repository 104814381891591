import React from 'react';
import styles from './Home.module.scss';
import DiscoverCareers from './Components/DiscoverCareers/DiscoverCareers';
import TakeQuiz from './Components/TakeQuiz/TakeQuiz';
import ExploreCareers from './Components/ExploreCareers/ExploreCareers';
const Home: React.FC = () => {
  return (
    <div id={styles.foundation}>
      <DiscoverCareers />
      <TakeQuiz />
      <ExploreCareers />
    </div>
  );
};

export default Home;
