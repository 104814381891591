import type { ReactNode, FC } from 'react';

import styles from './RolesInnerLayout2.module.scss';

interface RolesInnerLayout2Props {
  children: ReactNode;
}

const RolesInnerLayout2: FC<RolesInnerLayout2Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default RolesInnerLayout2;
