import React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './ProgressNum.module.scss';
import type RootStore from '../../../../stores/RootStore';

interface ProgressNumProps {
  rootStore?: RootStore;
}

const ProgressNum: React.FC<ProgressNumProps> = observer(({ rootStore }) => {
  const quizStore = rootStore?.quizStore;

  // If quizStore exists, index questions from 0, otherwise default to first question (index 0)
  const currentQuestionIndex =
    quizStore != null
      ? quizStore.questions.findIndex(
          (q) => q.id === quizStore.currentQuestionId,
        ) + 1
      : 1;

  // If quizStore exists, get the total number of questions, otherwise default to 0
  const totalQuestions = quizStore != null ? quizStore.questions.length : 0;

  return (
    <div className={styles.container}>
      <span>{`Question ${currentQuestionIndex} of ${totalQuestions}`}</span>
    </div>
  );
});

export default inject('rootStore')(ProgressNum);
