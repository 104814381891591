import React from 'react';
import styles from './Schools.module.scss';
import Intro from './Components/Intro/Intro';
import NotifyOnLaunch from './Components/NotifyOnLaunch/NotifyOnLaunch';

const Schools: React.FC = () => {
  return (
    <div id={styles.foundation}>
      <Intro />
      <NotifyOnLaunch />
    </div>
  );
};

export default Schools;
