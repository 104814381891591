import React from 'react';
import styles from './RolesDescription.module.scss';
import What from './What/What';
import patteredLines from '../../../../assets/images/Roles/patternedLines.svg';
import Why from './Why/Why';
import UsefulQualitiesnSkills from './UsefulQualitiesnSkills/UsefulQualitiesnSkills';
import type { Section1Data } from './../../../../types/RolePagesData';

const RolesDescription: React.FC<{ roleData: Section1Data; roleId: number }> =
  ({ roleData, roleId }) => {
  return (
    <div id={styles.foundation}>
      <div className={styles.container}>
        <img src={patteredLines} alt="Patterned image" />
        <div className={styles.innerContainer}>
          <What roleData1={roleData.what} roleId={roleId} />
          <Why roleData={roleData.why} roleId={roleId} />
          <UsefulQualitiesnSkills roleData={roleData.qualitiesAndSkills} />
        </div>
      </div>
    </div>
  );
};

export default RolesDescription;
