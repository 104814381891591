import { makeAutoObservable } from 'mobx';

import QuizStore from './QuizStore';
import ResultStore from './ResultStore';

class RootStore {
  quizStore: QuizStore;
  resultStore: ResultStore;
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.quizStore = new QuizStore(this);
    this.resultStore = new ResultStore();
  }
  
  setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}

export default RootStore;
