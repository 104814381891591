import React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './ProgressBar.module.scss';
import type RootStore from '../../../../stores/RootStore';

interface ProgressBarProps {
  rootStore?: RootStore;
}

const ProgressBar: React.FC<ProgressBarProps> = observer(({ rootStore }) => {
  const quizStore = rootStore?.quizStore;

  const currentQuestionIndex =
    quizStore != null
      ? quizStore.questions.findIndex(
          (q) => q.id === quizStore.currentQuestionId,
        )
      : 1;

  const totalQuestions = quizStore != null ? quizStore.questions.length : 0;

  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <div
          className={styles.progressBar}
          style={{
            width: `${(currentQuestionIndex / totalQuestions) * 100}%`,
          }}
        />
      </div>
    </div>
  );
});

export default inject('rootStore')(ProgressBar);
