import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

import styles from './SingleChoice.module.scss';
import type RootStore from '../../../../../stores/RootStore';

interface SingleChoiceProps {
  fadeOut: boolean;
  rootStore?: RootStore;
  evaluateQuiz: () => void;
}

const SingleChoice: React.FC<SingleChoiceProps> = observer(
  ({ fadeOut, rootStore, evaluateQuiz }) => {
    const quizStore = rootStore?.quizStore;
    const currentQuestionIndex =
      quizStore != null
        ? quizStore.questions.findIndex(
            (q) => q.id === quizStore.currentQuestionId,
          )
        : 1;
    const currentQuestion = quizStore?.questions[currentQuestionIndex];
    const [clickedOption, setClickedOption] = useState<string | null>(null);

    const handleOptionClick = (questionId: string, optionId: string): void => {
      setClickedOption(optionId);
      if (quizStore != null) {
        quizStore.selectOption(questionId, optionId);
      }
      setTimeout(() => {
        setClickedOption(null); // Reset after animation
        const lastQuestionIndex = (quizStore?.questions?.length ?? 0) - 1;
        if (currentQuestionIndex === lastQuestionIndex) {
          evaluateQuiz();
        }
      }, 150);
    };

    const handleKeyDown = (
      event: React.KeyboardEvent,
      questionId: string,
      optionId: string,
    ): void => {
      // Listen for the Enter or Space key presses
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        handleOptionClick(questionId, optionId);
      }
    };

    return (
      <div className={styles.container} aria-live="polite">
        {currentQuestion?.options.map((option, index) => (
          <button
            key={index}
            className={`${styles.option} ${
              clickedOption === option.id ? styles['option-clicked'] : ''
            }
          ${
            option.id === currentQuestion.selectedOptionId
              ? styles['option-selected']
              : ''
          }
          `}
            onClick={() => {
              handleOptionClick(currentQuestion.id, option.id);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e, currentQuestion.id, option.id);
            }}
            role="button"
            aria-label={`Choose option: ${option.text}`}
            aria-pressed={clickedOption === option.id ? 'true' : 'false'}
          >
            <span className={`${fadeOut ? styles.fadeOut : styles.answerText}`}>
              {option.text}
            </span>
          </button>
        ))}
      </div>
    );
  },
);

export default inject('rootStore')(SingleChoice);
