import React from 'react';
import styles from './CustomBold.module.scss';

interface ParserProps1 {
  text: string;
}

interface ParserProps2 {
  inputString: string;
}

const BoldComp: React.FC<ParserProps1> = ({ text }) => {
  const parts = text.split(/(\{[^}]*\})/);

  return (
    <span id={styles.boldP}>
      {parts.map((part, index) =>
        part.startsWith('{') && part.endsWith('}') ? (
          <strong id={styles.boldText} key={index}>
            {part.slice(1, -1)}
          </strong>
        ) : (
          part
        ),
      )}
    </span>
  );
};

const CustomBold: React.FC<ParserProps2> = ({ inputString }) => {
  return <BoldComp text={inputString} />;
};

export default CustomBold;
