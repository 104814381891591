import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../../components/Button/Button';
import HomeLayout from '../../../../layouts/Home/HomeLayout';
import HomeInnerLayout1 from '../../../../layouts/Home/innerLayout1/HomeInnerLayout1';
import HomeInnerLayout2 from '../../../../layouts/Home/innerLayout2/HomeInnerLayout2';

import PatternedLine from '../../../../assets/images/Home/TakeQuiz/patternedLines.svg';
import girlOnPhoneImg from '../../../../assets/images/Home/TakeQuiz/girlOnPhone.jpg';

import styles from './TakeQuiz.module.scss';

const TakeQuiz: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src={PatternedLine} alt='Patterned line image'/>
      <div>
        <HomeLayout flexType="wrap-reverse">
          <HomeInnerLayout1>
            <div className={styles.innerContainer}>
              <h2>Discover Your Tech Talent</h2>
              <p className={styles.p2}>
                Take our quiz and discover your hidden tech talent & suitable
                careers!
              </p>
              <div>
                <Link to="/quiz">
                  <Button>Get started</Button>
                </Link>
              </div>
            </div>
          </HomeInnerLayout1>
          <HomeInnerLayout2>
            <img
              src={girlOnPhoneImg}
              className={styles.girlWithPhoneImg}
              alt='Girl on a phone image' />
          </HomeInnerLayout2>
        </HomeLayout>
      </div>
    </div>
  );
};

export default TakeQuiz;
