import React from 'react';
import { observer, inject } from 'mobx-react';

import styles from './QuizIntro.module.scss';
import type RootStore from '../../stores/RootStore';
import QuizFrame from '../Quiz/Components/QuizFrame/QuizFrame';
import QuestionDescription from '../Quiz/Components/QuestionDescription/QuestionDescription';

interface QuizIntroProps {
  rootStore?: RootStore;
}

const QuizIntro: React.FC<QuizIntroProps> = observer(({ rootStore }) => {
  return (
    <div className={styles.container}>
      <h1>Mission Technius Quiz</h1>
      <QuestionDescription
        sectionTitle="Tech for YOU?"
        sectionSubTitle="Take the quiz & see!"
      ></QuestionDescription>
      <QuizFrame isIntroPage></QuizFrame>
    </div>
  );
});

export default inject('rootStore')(QuizIntro);
