import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import styles from './QuizFrame.module.scss';
import type RootStore from '../../../../stores/RootStore';
import ProgressNum from '../ProgressNum/ProgressNum';
import Question from '../Question/Question';
import Answers from '../Answers/Answers';
import ProgressBar from '../ProgressBar/ProgressBar';
import BackButton from '../BackButton/BackButton';
import DoneButton from '../DoneButton/DoneButton';
import { evaluateAnswersData } from '../../../../services/api';
import { type QuizAnswerData } from '../../../../types/QuizData';
import { type EvaluationResponse } from '../../../../types';
import Button from '../../../../components/Button/Button';
import IntroContent from '../../../QuizIntro/Components/IntroContent/IntroContent';

interface QuizFrameProps {
  rootStore?: RootStore;
  isIntroPage: boolean;
}

const QuizFrame: React.FC<QuizFrameProps> = observer(
  ({ rootStore, isIntroPage = false }) => {
    const navigate = useNavigate();
    const quizStore = rootStore?.quizStore;
    const currentQuestionIndex =
      quizStore != null
        ? quizStore.questions.findIndex(
            (q) => q.id === quizStore.currentQuestionId,
          )
        : 1;
    const currentQuestion = quizStore?.questions[currentQuestionIndex];

    // TODO: Add https://www.google.com/recaptcha/intro/invisible.html
    const evaluateQuiz = async (): Promise<void> => {
      if (quizStore == null) {
        console.error('Quiz Store is not available');
        return;
      }

      rootStore?.setLoading(true);

      try {
        const payload: QuizAnswerData = {
          questions: quizStore.questions,
        };

        const { data, error } = await evaluateAnswersData(payload);

        if (error != null) {
          if (error.includes('Rate limit exceeded')) {
            alert(
              'You have reached the rate limit. Please wait a moment before trying again.',
            );
          } else {
            alert('An error occurred during the quiz evaluation. Please try again later.');
            console.error('Error during quiz evaluation:', error);
          }
          return;
        }

        if (data?.status === 200) {
          rootStore?.resultStore.setResult(data.message as EvaluationResponse);
          rootStore?.quizStore.clearStore();
          navigate('/quiz-results');
        } else {
          console.error('API returned non-200 status:', data);
        }
      } catch (error: unknown) {
        console.error('An unknown error occurred:', error);
      } finally {
        rootStore?.setLoading(false);
      } 
    };

    const intermediateEvaluateQuiz = (): void => {
      evaluateQuiz().catch((err) => {
        console.error('Error during quiz evaluation:', err);
      });
    };

    return (
      <div className={styles.containerWrapper}>
        {!isIntroPage && (
          <div className={styles.container}>
            <ProgressNum></ProgressNum>
            <ProgressBar></ProgressBar>
            <Question></Question>
            <Answers evaluateQuiz={intermediateEvaluateQuiz}></Answers>
            <nav className={styles.bottomQuizNav}>
              <BackButton isIntro={false}></BackButton>
              {currentQuestion?.questionType === 'multiple' ? (
                <DoneButton
                  evaluateQuiz={intermediateEvaluateQuiz}
                ></DoneButton>
              ) : null}
            </nav>
          </div>
        )}
        {isIntroPage && (
          <div className={styles.container}>
            <div className={styles.quizIntro}>
              <IntroContent></IntroContent>
              <Link to="/quiz-start">
                <Button type="gradient">Take the Quiz</Button>
              </Link>
              <nav className={styles.bottomQuizNav}>
                <BackButton isIntro></BackButton>
              </nav>
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default inject('rootStore')(QuizFrame);
