import React from 'react';
import styles from './UsefulQualitiesnSkills.module.scss';
import tickImg from '../../../../../assets/icons/RoundPinkTick.svg';
import type { qualitiesAndSkills } from '../../../../../types/RolePagesData';

const UsefulQualitiesnSkills: React.FC<{roleData: qualitiesAndSkills}> = ({ roleData }) => {
  return (
    <div id={styles.foundation}>
      <h3>{roleData.title}</h3>
      {roleData.paragraphs?.map((element, index) => (
        <p key={index}>{element}</p>
      ))}

      <div className={styles.container}>
        {roleData.qualitiesAndSkills.map((element, index) => {
          return (
            <div className={styles.boxs} key={index}>
              <img src={tickImg} alt="Tick image" />
              <div>{element}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UsefulQualitiesnSkills;
