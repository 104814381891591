import React from 'react';
import styles from './Intro.module.scss';
import schoolStudents from '../../../../assets/images/Schools/school-students.png';

const Intro: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.hero}>
        <div className={styles.innerContainer}>
          <h2>Technius for Schools</h2>
          <p className={styles.p2}>
            Technius bridges the gap between education and the tech industry,
            tailored for New Zealand schools. By integrating Technius, schools
            can provide students with insights into diverse tech careers,
            helping them discover their strengths and interests.
            <br />
            <br />
            Our platform supports educators and career advisors in guiding
            students towards exciting opportunities in technology, preparing the
            next generation for a bright future in tech.
          </p>
        </div>
        <img
          src={schoolStudents}
          className={styles.schoolStudents}
          alt="A group of School students"
        />
      </div>
    </div>
  );
};

export default Intro;
