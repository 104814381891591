import React from 'react';
import styles from './NextSteps.module.scss';
import tickImg from '../../../../assets/icons/RoundPinkTick.svg';
import LinkParser from '../../../../components/LinkParser/LinkParser';
import ExploreOtherRoles from '../ExploreOtherRoles/ExploreOtherRoles';
interface RoleData {
  heading: string;
  paragraphs?: string[];
  steps?: string[];
}
interface RolesProps {
  roleData: RoleData;
}
const NextSteps: React.FC<RolesProps> = ({ roleData }) => {
  return (
    <div id={styles.foundation}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <h2>{roleData.heading}</h2>
          {roleData.paragraphs?.map((elem, index) => {
            return <LinkParser key={index} inputString={elem}></LinkParser>;
          })}
          {roleData.steps?.map((elem, index) => {
            return (
              <div className={styles.boxs} key={index}>
                <img src={tickImg} alt='Tick image'/>
                <LinkParser inputString={elem} tagType='a'></LinkParser>
              </div>
            );
          })}
          <ExploreOtherRoles/>
        </div>
      </div>
    </div>
  );
};

export default NextSteps;
