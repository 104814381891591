import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';

import type RootStore from '../../../../stores/RootStore';
// eslint-disable-next-line max-len
import { ReactComponent as DoubleArrowRightPink } from '../../../../assets/images/Home/ExploreCareers/DoubleArrow.svg';
import { ReactComponent as SingleArrowRight } from '../../../../assets/icons/SingleRightArrow.svg';
import styles from './IntroContent.module.scss';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

interface IntroContentProps {
  rootStore?: RootStore;
}

const IntroContent: React.FC<IntroContentProps> = observer(({ rootStore }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = (): void => {
    setShowDetails(!showDetails);
  };

  return (
    <div className={styles.container}>
      <h3>Find your tech groove! Answer 21 quick questions and unlock:</h3>
      <ul className={styles.customList}>
        <li>
          <DoubleArrowRightPink
            className={styles.bulletIcon}
            aria-hidden="true"
            aria-label="Custom bullet point icon"
          />
          <span>Top tech role suggestions for you</span>
        </li>
        <li>
          <DoubleArrowRightPink
            className={styles.bulletIcon}
            aria-hidden="true"
            aria-label="Custom bullet point icon"
          />
          <span>Skills you might love using ✨</span>
        </li>
        <li>
          <DoubleArrowRightPink
            className={styles.bulletIcon}
            aria-hidden="true"
            aria-label="Custom bullet point icon"
          />
          <span>Careers that light your fire!</span>
        </li>
        <li>
          <DoubleArrowRightPink
            className={styles.bulletIcon}
            aria-hidden="true"
            aria-label="Custom bullet point icon"
          />
          <span>
            Plus: Salaries, study options & FREE results you can share!{' '}
          </span>
        </li>
      </ul>
      <h3 className={styles.knowMore} onClick={toggleDetails}>
        Want to know more? <SingleArrowRight className={styles.arrowIcon} />
      </h3>
      {showDetails && (
        <div className={styles.knowMoreDetail}>
          <motion.details
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            open
          >
            <summary>This quick quiz is for:</summary>
            <p>School Leavers: Explore your options before diving in!</p>
            <p>
              Career Changers: Take control of your future. Explore tech
              options!
            </p>
            <p>Anyone curious: Dive in and expect the unexpected!</p>
          </motion.details>
          <motion.details
            transition={{ delay: 0.2 }}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            open
          >
            <summary>You will discover:</summary>
            <p>Career Ideas</p>
            <p>Hidden Skills</p>
            <p>Level up your life! Free, anonymous quiz. Takes 5 minutes.</p>
          </motion.details>
          <motion.div
            transition={{ delay: 0.3 }}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
          >
            <Link to="/why-technius" className={styles.findOutMoreContainer}>
              <h2 className={styles.findOutMore}>Find out more about the quiz</h2>
            </Link>
          </motion.div>
        </div>
      )}
    </div>
  );
});

export default inject('rootStore')(IntroContent);
