import styles from './My404Component.module.scss';
import logo from '../../assets/images/MTlogowhite2.png';
import { Link } from 'react-router-dom';

const My404Component: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/home">
          <img id={styles.technus404img} src={logo} />
        </Link>

        <h1>Lost in space?</h1>
        <p>
          The page you are looking for is not in this galaxy
          <br />
          Click on our logo to go home and re-launch
        </p>
        <div id={styles.RocketPhoto} />
      </div>
    </div>
  );
};

export default My404Component;
