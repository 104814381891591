import { Navigate } from 'react-router-dom';

import RootLayout from '../layouts/RootLayout';
import { RouteTracker } from '../components/RouteTracker/RouteTracker';
import My404Component from '../components/My404Component/My404Component';
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import Home from '../pages/Home/Home';
import Quiz from '../pages/Quiz/Quiz';
import Schools from '../pages/Schools/Schools';
import QuizResults from '../pages/QuizResults/QuizResults';
import ExploreCareers from '../pages/ExploreCareers/ExploreCareers';
import QuizIntro from '../pages/QuizIntro/QuizIntro';
import TermsNConditions from '../pages/TermsNConditions/TermsNConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import Roles from '../pages/Roles/Roles';
import WhyTechnius from '../pages/WhyTechnius/WhyTechnius';

export const routes = [
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <RouteTracker />
        <RootLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />,
      },
      // Route redirection if needed
      // {
      //   path: 'home',
      //   element: <Navigate to="/home" replace relative='path' />,
      // },
      {
        path: 'home',
        element: (
          <ErrorBoundary>
            <Home />
          </ErrorBoundary>
        ),
      },
      {
        path: 'quiz',
        element: (
          <ErrorBoundary>
            <QuizIntro />
          </ErrorBoundary>
        ),
      },
      {
        path: 'schools',
        element: (
          <ErrorBoundary>
            <Schools />
          </ErrorBoundary>
        ),
      },
      {
        path: 'quiz-start',
        element: (
          <ErrorBoundary>
            <Quiz />
          </ErrorBoundary>
        ),
      },
      {
        path: 'quiz-results',
        element: (
          <ErrorBoundary>
            <QuizResults />
          </ErrorBoundary>
        ),
      },
      {
        path: 'explore',
        element: (
          <ErrorBoundary>
            <ExploreCareers />
          </ErrorBoundary>
        ),
      },
      {
        path: 'terms',
        element: (
          <ErrorBoundary>
            <TermsNConditions />
          </ErrorBoundary>
        ),
      },
      {
        path: 'privacy',
        element: (
          <ErrorBoundary>
            <PrivacyPolicy />
          </ErrorBoundary>
        ),
      },
      {
        path: 'why-technius',
        element: (
          <ErrorBoundary>
            <WhyTechnius />
          </ErrorBoundary>
        ),
      },
      {
        path: 'roles/:id',
        element: (
          <ErrorBoundary>
            <Roles />
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <My404Component />,
  },
];
