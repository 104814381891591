import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import styles from './QuestionDescription.module.scss';
import type RootStore from '../../../../stores/RootStore';

interface QuestionDescriptionProps {
  rootStore?: RootStore;
  sectionTitle?: string; // Optional prop for section title
  sectionSubTitle?: string; // Optional prop for section subtitle
}

const QuestionDescription: React.FC<QuestionDescriptionProps> = observer(
  ({ rootStore, sectionTitle, sectionSubTitle }) => {
    const quizStore = rootStore?.quizStore;
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
      setFadeOut(true);
      const timer = setTimeout(() => {
        setFadeOut(false);
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    }, [quizStore?.currentQuestionId]);

    // If quizStore exists, index questions from 0, otherwise default to first question (index 0)
    const currentQuestionIndex =
      quizStore != null
        ? quizStore.questions.findIndex(
            (q) => q.id === quizStore.currentQuestionId,
          )
        : 1;

    const currentQuestion = quizStore?.questions[currentQuestionIndex];
    const title = sectionTitle ?? currentQuestion?.sectionTitle;
    const subTitle = sectionSubTitle ?? currentQuestion?.sectionSubTitle;

    return (
      <div className={styles.containerWrapper}>
        <div className={styles.container} aria-live="assertive">
          <div className={styles.sectionText}>
            <h2 className={`${fadeOut ? styles.fadeOut : styles.titleText}`}>
              {title}
            </h2>
            {subTitle != null && (
              <span
                className={`${fadeOut ? styles.fadeOut : styles.subTitleText}`}
              >
                {subTitle}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default inject('rootStore')(QuestionDescription);
