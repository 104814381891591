import React from 'react';

import styles from './ExporeTechRoles.module.scss';
import GroupImage from '../../../../assets/images/ExploreCareers/ExploreImg.jpg';

const ExporeTechRoles: React.FC = () => {
  return (
    <div id={styles.Foundation}>
      <div id={styles.Container1}>
        <h1>Explore Tech Roles</h1>
        <p>
          Tech&apos;s full of cool jobs that don&apos;t always need maths,
          science, or uni degrees!
        </p>
        <p>
          Check out these eight main areas to see where you fit. Curious? Take
          our quiz for some personalised career ideas.
        </p>
      </div>
      <div id={styles.Container2}>
        <img src={GroupImage} />
      </div>
    </div>
  );
};

export default ExporeTechRoles;
