import React, { useEffect } from 'react';
import styles from './Roles.module.scss';
import RolesIntro from './Components/RolesIntro/RolesIntro';
import RolesDescription from './Components/RolesDescription/RolesDescription';
import JobDetails from './Components/JobDetails/ChooseYourPath';
import HowToGetStarted from './Components/HowToGetStarted/HowToGetStarted';
import NextSteps from './Components/NextSteps/NextSteps';
import RolesJson from '../../config/RolePagesData.json';
import { useParams,useNavigate } from 'react-router-dom';

const Roles: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const currRoleId = {
    SoftwareTester: 4,
    SoftwareDeveloper: 1,
    SoftwareDesigner: 7,
    BusinessAnalyst: 6,
    SupportAndAdmin: 2,
    MarketingAndSales: 8,
    ProjectManager: 5,
    DataAnalyst: 3,
  };
  const currentRoleId = currRoleId[id as keyof typeof currRoleId];
  const RolePageData = RolesJson.roles.find(
    (elem) => elem.roleId === currentRoleId,
  );
  useEffect(() => {
    if(currentRoleId===undefined){
    navigate('/page-not-found');
  };
  }, []);
  
  return (
    <div>
      {RolePageData?.content !== null &&
        RolePageData?.content !== undefined && (
          <div id={styles.container}>
            <RolesIntro
              roleData={RolePageData.content.intro}
              roleId={RolePageData.roleId}
            />
            <RolesDescription
              roleData={RolePageData.content.section1}
              roleId={RolePageData.roleId}
            />
            <JobDetails
              roleData={RolePageData.content.section2}
              roleId={RolePageData.roleId}
            />
            <HowToGetStarted
              roleData1={RolePageData.content.section3.How}
              roleData2={RolePageData.content.section3.certsAndQualifications}
            />
            <NextSteps roleData={RolePageData.content.section3.nextSteps} />
          </div>
        )}
    </div>
  );
};

export default Roles;
