import React, { useEffect, useState } from 'react';
import styles from './TypeOfTechRoles.module.scss';
import CheckCircle from '../../../../assets/images/ExploreCareers/iconCheckCircle.svg';
import TechRoleArrow from '../../../../assets/images/ExploreCareers/TechRoles/TechRoleArrow.svg';
import ExploreRolesJson from '../../../../config/roleData.json';

import SoftwareDesignImg from '../../../../assets/images/ExploreCareers/TechRoles/palette.svg';
// eslint-disable-next-line max-len
import SoftwareDeveloperIMG from '../../../../assets/images/ExploreCareers/TechRoles/codescreen.svg';
import SupportnAdminImg from '../../../../assets/images/ExploreCareers/TechRoles/support.svg';
import MarketingnSales from '../../../../assets/images/ExploreCareers/TechRoles/megaphone.svg';
import BusinessAnalyst from '../../../../assets/images/ExploreCareers/TechRoles/meeting.svg';
import ProjectManager from '../../../../assets/images/ExploreCareers/TechRoles/calendar.svg';
import SoftwareTester from '../../../../assets/images/ExploreCareers/TechRoles/clipboard.svg';
import DataAnalyst from '../../../../assets/images/ExploreCareers/TechRoles/graph.svg';
import { Link } from 'react-router-dom';

interface ExploreRole {
  roleId: number;
  title: string;
  values: string[];
  description: string;
  link: string;
}

const TypeOfTechRoles: React.FC = () => {
  const ExploreRoles = ExploreRolesJson.roles;
  const [ExploreRoleArray, setExploreRoleArray] = useState<ExploreRole[]>([]);
  const order = [7, 1, 2, 8, 6, 5, 4, 3];
  const ExploreRoleImages = {
    7: SoftwareDesignImg,
    1: SoftwareDeveloperIMG,
    2: SupportnAdminImg,
    8: MarketingnSales,
    6: BusinessAnalyst,
    5: ProjectManager,
    4: SoftwareTester,
    3: DataAnalyst,
  };
  useEffect(() => {
    order.forEach((element) => {
      const foundRole = ExploreRoles.find((elem) => elem.roleId === element);

      if (foundRole !== undefined) {
        setExploreRoleArray((prevArray) => [...prevArray, foundRole]);
      }
    });

    // console.log(ExploreRoleArray);
  }, []);
  return (
    <div id={styles.foundation}>
      <div id={styles.container}>
        <h2>Unlock the tech world!</h2>
        <p>Explore tasks, skills, salaries, roles, and how to get started.</p>
        <div id={styles.innerContainer}>
          {ExploreRoleArray.map((element, index) => {
            const roleId = element.roleId as keyof typeof ExploreRoleImages;

            return (
              <div className={styles.boxs} key={index}>
                <div className={styles.boxsContainer1}>
                  <img
                    src={ExploreRoleImages[roleId]}
                    alt={`Role ${element.title}`}
                  />
                </div>
                <div className={styles.boxsContainer2}>
                  <Link
                    to={element.link}
                    className={styles.title}
                  >
                    <h3>{element.title}</h3>
                  </Link>
                  <div className={styles.valuesContainer}>
                    {element.values.map((elem, innerIndex) => {
                      return (
                        <div key={innerIndex}>
                          <img src={CheckCircle} alt="Check Circle" />
                          {elem}
                        </div>
                      );
                    })}
                  </div>
                  <p>{element.description}</p>
                  <Link
                    className={styles.TechRoleBoxButtonContainer}
                    to={element.link}
                  >
                    <div>Read more</div>
                    <img src={TechRoleArrow} alt="Tech Role Arrow" width={35} />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TypeOfTechRoles;
