import type { ReactNode, FC } from 'react';

import styles from './HomeLayout.module.scss';

interface HomeLayoutProps {
  children: ReactNode;
  flexType?: 'wrap' | 'wrap-reverse';
}

const HomeLayout: FC<HomeLayoutProps> = ({ children, flexType }) => {
  return (
    <div
      className={flexType === 'wrap' ? styles.container1 : styles.container2}
    >
      {children}
    </div>
  );
};

export default HomeLayout;
