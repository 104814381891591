import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import { observer, inject } from 'mobx-react';

import type RootStore from '../../stores/RootStore';
import styles from './TermsNConditions.module.scss';

interface TermsNConditionsProps {
  rootStore?: RootStore;
}

const TermsNConditions: React.FC<TermsNConditionsProps> = observer(
  ({ rootStore }) => {
    const [terms, setTerms] = useState('');

    useEffect(() => {
      void fetch('/termsNConditions.md')
        .then(async (res) => await res.text())
        .then((text) => {
          setTerms(text);
        });
    }, []);

    return (
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <h2 className={styles.title}>Terms and Conditions </h2>
          <Markdown>{terms}</Markdown>
        </div>
      </div>
    );
  },
);

export default inject('rootStore')(TermsNConditions);
