import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import styles from './Answer.module.scss';
import type RootStore from '../../../../stores/RootStore';
import MultiChoice from './MultiChoice/MultiChoice';
import SingleChoice from './SingleChoice/SingleChoice';

interface AnswerProps {
  rootStore?: RootStore;
  evaluateQuiz: () => void;
}

const Answers: React.FC<AnswerProps> = observer(
  ({ rootStore, evaluateQuiz }) => {
    const quizStore = rootStore?.quizStore;
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
      setFadeOut(true);
      const timer = setTimeout(() => {
        setFadeOut(false);
      }, 250);
      return () => {
        clearTimeout(timer);
      };
    }, [quizStore?.currentQuestionId]);

    // If quizStore exists, index questions from 0, otherwise default to first question (index 0)
    const currentQuestionIndex =
      quizStore != null
        ? quizStore.questions.findIndex(
            (q) => q.id === quizStore.currentQuestionId,
          )
        : 1;

    const currentQuestion = quizStore?.questions[currentQuestionIndex];

    return (
      <div className={styles.container} aria-live="polite">
        {currentQuestion?.questionType === 'single' ? (
          <SingleChoice evaluateQuiz={evaluateQuiz} fadeOut={fadeOut} />
        ) : (
          <>
            <MultiChoice fadeOut={fadeOut} />
          </>
        )}
      </div>
    );
  },
);

export default inject('rootStore')(Answers);
