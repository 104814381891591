import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import ReactGA from 'react-ga4';

export function RouteTracker(): React.JSX.Element {
  const location = useLocation();
  const navigationType = useNavigationType();

  const pathToTitleMap: Record<string, string> = {
    '/': 'Website Home',
    '/home': 'Home Page',
    '/quiz': 'Quiz Introduction',
    '/quiz-start': 'Quiz Questions',
    '/quiz-results': 'Quiz Results',
    '/explore': 'Explore Careers',
    '/terms': 'Terms and Conditions',
    '/schools': 'Technius for Schools',
    '/*': '404 Not Found',
  };

  useEffect(() => {
    if (navigationType === 'PUSH' || navigationType === 'POP') {
      const title = pathToTitleMap[location.pathname] ?? 'Unknown Page';
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
        title,
      });
    }
  }, [location, navigationType]);
  return <></>;
}
