import React from 'react';
import { inject, observer } from 'mobx-react';

import styles from './MultiChoice.module.scss';
import type RootStore from '../../../../../stores/RootStore';
import { ReactComponent as Tick } from '../../../../../assets/icons/Tick.svg';

interface MultiChoiceProps {
  fadeOut: boolean;
  rootStore?: RootStore;
}

const MultiChoice: React.FC<MultiChoiceProps> = observer(
  ({ rootStore, fadeOut }) => {
    const quizStore = rootStore?.quizStore;
    const currentQuestionIndex =
      quizStore != null
        ? quizStore.questions.findIndex(
            (q) => q.id === quizStore.currentQuestionId,
          )
        : 1;

    const currentQuestion = quizStore?.questions[currentQuestionIndex];
    const selectedOptionIds = (
      Array.isArray(currentQuestion?.selectedOptionId)
        ? currentQuestion?.selectedOptionId
        : []
    ) as string[];

    const handleCheckboxChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      const optionId = e.target.value;
      let newSelectedOptions = [...selectedOptionIds];

      if (e.target.checked) {
        newSelectedOptions.push(optionId);
      } else {
        newSelectedOptions = newSelectedOptions.filter((id) => id !== optionId);
      }

      if (currentQuestion != null) {
        if (quizStore != null) {
          quizStore.selectOption(currentQuestion.id, newSelectedOptions);
        }
      }
    };

    return (
      <div
        className={`${fadeOut ? styles.fadeOut : styles.answerText} ${
          styles.container
        }`}
        aria-live="polite"
      >
        {currentQuestion?.options.map((option, index) => (
          <>
            <input
              key={`input` + index}
              id={option.id}
              type="checkbox"
              className={styles.realCheckbox}
              value={option.id}
              checked={selectedOptionIds.includes(option.id)}
              onChange={handleCheckboxChange}
              aria-label={`${option.text}`}
              aria-pressed={
                selectedOptionIds.includes(option.id) ? 'true' : 'false'
              }
            />
            <label
              key={index}
              htmlFor={option.id}
              className={`${styles.customCheckbox} 
            ${
              selectedOptionIds.includes(option.id) ? styles.optionSelected : ''
            }`}
            >
              {selectedOptionIds.includes(option.id) && (
                <Tick className={styles.tick} />
              )}
              {option.text}
            </label>
          </>
        ))}
      </div>
    );
  },
);

export default inject('rootStore')(MultiChoice);
