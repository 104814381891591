import React from 'react';
import styles from './ChooseYourPath.module.scss';
import RolesLayout from '../../../../layouts/Roles/RolesLayout';
import RolesInnerLayout1 from '../../../../layouts/Roles/innerLayout1/RolesInnerLayout1';
import RolesInnerLayout2 from '../../../../layouts/Roles/innerLayout2/RolesInnerLayout2';
import roleId4Img from '../../../../assets/images/Roles/SoftwareTester/image4.jpg';
import roleId1Img from '../../../../assets/images/Roles/SoftwareDeveloper/image4.jpg';
import roleId7Img from '../../../../assets/images/Roles/SoftwareDesigner/image4.jpg';
import roleId6Img from '../../../../assets/images/Roles/BusinessAnalyst/image4.jpg';
import roleId2Img from '../../../../assets/images/Roles/Support&Admin/image4.jpg';
import roleId8Img from '../../../../assets/images/Roles/MarketingAndSales/image4.jpg';
import roleId5Img from '../../../../assets/images/Roles/ProjectManager/image4.jpg';
import roleId3Img from '../../../../assets/images/Roles/DataAnalyst/image4.jpg';
import CustomBold from '../../../../components/CustomBold/CustomBold';
import type { RolesProps } from '../../../../types/RolePagesData';
const ChooseYourPath: React.FC<RolesProps> = ({ roleData, roleId }) => {
  const roleImgs = [
    { id: 4, img: roleId4Img },
    { id: 1, img: roleId1Img },
    { id: 7, img: roleId7Img },
    { id: 6, img: roleId6Img },
    { id: 2, img: roleId2Img },
    { id: 8, img: roleId8Img },
    { id: 5, img: roleId5Img },
    { id: 3, img: roleId3Img },
  ];
  const roleImg = roleImgs.find((elem) => elem.id === roleId);
  return (
    <div id={styles.foundation}>
      <div className={styles.container}>
        <RolesLayout>
          <RolesInnerLayout1>
            <div className={styles.innerContainer1}>
              <h2 id={styles.pcHeader}>{roleData.heading}</h2>
              {roleData.salary.paragraphs.map((elem, index) => {
                return (
                  <p key={index}>
                    <CustomBold inputString={elem}></CustomBold>
                  </p>
                );
              })}
            </div>
          </RolesInnerLayout1>
          <RolesInnerLayout2>
            <h2 id={styles.mobileHeader}>{roleData.heading}</h2>
            <img src={roleImg?.img} alt={roleData.image} />
          </RolesInnerLayout2>
        </RolesLayout>
      </div>
    </div>
  );
};

export default ChooseYourPath;
