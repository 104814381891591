import React from 'react';
import { observer, inject } from 'mobx-react';

import styles from './Suggestion.module.scss';
import type RootStore from '../../../../stores/RootStore';

interface SuggestionProps {
  rootStore?: RootStore;
}

const Suggestion: React.FC<SuggestionProps> = observer(({ rootStore }) => {
  const resultStore = rootStore?.resultStore;

  return (
    <div className={styles.container}>
      <p>{resultStore?.suggestionMessage}</p>
    </div>
  );
});

export default inject('rootStore')(Suggestion);
