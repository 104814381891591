import React from 'react';
import { observer, inject } from 'mobx-react';

import styles from './Quiz.module.scss';
import type RootStore from '../../stores/RootStore';
import QuizFrame from './Components/QuizFrame/QuizFrame';
import QuestionDescription from './Components/QuestionDescription/QuestionDescription';

interface QuizProps {
  rootStore?: RootStore;
}

const Quiz: React.FC<QuizProps> = observer(({ rootStore }) => {
  return (
    <div className={styles.container}>
      <h1>Mission Technius Quiz</h1>
      <QuestionDescription></QuestionDescription>
      <QuizFrame isIntroPage={false}></QuizFrame>
    </div>
  );
});

export default inject('rootStore')(Quiz);
