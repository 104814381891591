import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import type RootStore from '../stores/RootStore';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Loading from '../components/Loading/Loading';

import styles from './RootLayout.module.scss';

interface RootLayoutProps {
  rootStore?: RootStore;
}

const RootLayout: React.FC<RootLayoutProps> = observer(({ rootStore }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/home';
  const isExplorePage = location.pathname === '/explore';
  const isSchoolsPage = location.pathname === '/schools';
  const isQuizPage = location.pathname === '/quiz';
  const isQuizIntroPage = location.pathname === '/quiz-start';

  const containerClass =
    isHomePage || isExplorePage || isSchoolsPage
      // eslint-disable-next-line max-len
      ? `${styles.container} ${styles.homePage}` // Apply homePage style for both home, explore & schools
      : isQuizPage
      ? `${styles.container} ${styles.quizPage}`
      : styles.container;

  useEffect(() => {
    // Scroll top when location changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={containerClass}>
      {((rootStore?.isLoading) ?? false) && <Loading />}
      <Header />
      <main>
        <Outlet></Outlet>
      </main>
      {(!isQuizPage || !isQuizIntroPage) && <Footer />}
    </div>
  );
});

export default inject('rootStore')(RootLayout);