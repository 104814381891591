import React from 'react';
import styles from './What.module.scss';
import RolesLayout from '../../../../../layouts/Roles/RolesLayout';
import RolesInnerLayout2 from '../../../../../layouts/Roles/innerLayout2/RolesInnerLayout2';
import RolesInnerLayout1 from '../../../../../layouts/Roles/innerLayout1/RolesInnerLayout1';
import type { RoleDataWhat } from '../../../../../types/RolePagesData';
import roleId4Img from '../../../../../assets/images/Roles/SoftwareTester/image2.jpg';
import roleId1Img from '../../../../../assets/images/Roles/SoftwareDeveloper/image2.jpg';
import roleId7Img from '../../../../../assets/images/Roles/SoftwareDesigner/image2.jpg';
import roleId6Img from '../../../../../assets/images/Roles/BusinessAnalyst/image2.jpg';
import roleId2Img from '../../../../../assets/images/Roles/Support&Admin/image2.jpg';
import roleId8Img from '../../../../../assets/images/Roles/MarketingAndSales/image2.jpg';
import roleId5Img from '../../../../../assets/images/Roles/ProjectManager/image2.jpg';
import roleId3Img from '../../../../../assets/images/Roles/DataAnalyst/image2.jpg';
import CustomBold from '../../../../../components/CustomBold/CustomBold';

const What: React.FC<{ roleData1: RoleDataWhat; roleId: number}> = ({ roleData1, roleId }) => {
  const roleImgs = [
    { id: 4, img: roleId4Img },
    { id: 1, img: roleId1Img },
    { id: 7, img: roleId7Img },
    { id: 6, img: roleId6Img },
    { id: 2, img: roleId2Img },
    { id: 8, img: roleId8Img },
    { id: 5, img: roleId5Img },
    { id: 3, img: roleId3Img },
  ];
  const roleImg = roleImgs.find((elem) => elem.id === roleId);
  return (
    <div id={styles.foundation}>
      <RolesLayout>
        <RolesInnerLayout1>
          <div className={styles.container}>
            <div className={styles.innerContainer1}>
              <h2 id={styles.pcHeader}>{roleData1.header}</h2>
              {roleData1.paragraphs.map((elem, index) => {
                return (
                  <p className={styles.p1} key={index}>
                    <CustomBold inputString={elem} />
                  </p>
                );
              })}
            </div>
          </div>
        </RolesInnerLayout1>
        <RolesInnerLayout2>
          <h2 id={styles.mobileHeader}>{roleData1.header}</h2>
          <img src={roleImg?.img} alt={roleData1.image} />
        </RolesInnerLayout2>
      </RolesLayout>
    </div>
  );
};

export default What;
