import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { ROLE_ID, SUGGESTION_TYPE_ID } from '../../constants';
import type RootStore from '../../stores/RootStore';
import styles from './QuizResults.module.scss';
import Suggestion from './Components/Suggestion/Suggestion';
import RoleCard from './Components/RoleCard/RoleCard';
import EmailResult from './Components/EmailResult/EmailResult';

import { ReactComponent as SingleRightArrow } from '../../assets/icons/SingleRightArrow.svg';

interface QuizResultsProps {
  rootStore?: RootStore;
}

const QuizResults: React.FC<QuizResultsProps> = observer(({ rootStore }) => {
  const result = rootStore?.resultStore.result;
  const suggestionType = rootStore?.resultStore.suggestionType;

  const hasSomeSuggestions =
    result?.mainRoles != null &&
    result.mainRoles.length > 0 &&
    result.mainRoles[0] !== ROLE_ID.None;

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <h2>Quiz Results - Our Suggestions for You </h2>
        <Suggestion />

        {!hasSomeSuggestions && (
          <p>
            To keep a copy of these suggestions, you can email the results to
            yourself.
          </p>
        )}
        <h3 className={styles.otherRoles}>
          Check out these roles or
          <NavLink to="/explore"> explore other tech roles.</NavLink>
        </h3>
        {suggestionType === SUGGESTION_TYPE_ID.HasNoResult && (
          <h4>
            You can also <NavLink to="/quiz">re-take the quiz.</NavLink>
          </h4>
        )}
        <div className={styles.roleCards}>
          {result?.mainRoles.map((roleId: ROLE_ID, index: number) => (
            <RoleCard roleId={roleId} key={index}></RoleCard>
          ))}
          {!hasSomeSuggestions &&
            rootStore?.resultStore?.defaultRoles.map(
              (roleId: ROLE_ID, index: number) => (
                <RoleCard roleId={roleId} key={index}></RoleCard>
              ),
            )}
        </div>
        <EmailResult></EmailResult>
        <Link to="/explore" className={styles.exploreLink}>
          <h2>
            <strong>Explore other tech roles</strong>{' '}
            <SingleRightArrow id={styles.exploreImg} />
          </h2>
        </Link>
      </div>
    </div>
  );
});

export default inject('rootStore')(QuizResults);
