/* eslint-disable max-len */
export enum ROLE_ID {
  None = 0,
  Developer = 1,
  SupportAdmin = 2,
  DataAnalyst = 3,
  Tester = 4,
  ProjectManager = 5,
  BusinessAnalyst = 6,
  UXUIDesigner = 7,
  SalesMarketing = 8,
}

export enum EXTRA_ROLES_ROLE_ID {
  None = 0,
  Engineering = 1,
}

export type RoleName =
  | "We can't find a good match to our roles..."
  | 'Software Developer'
  | 'Support & Admin'
  | 'Data Analyst'
  | 'Software Tester'
  | 'Project Manager'
  | 'Business Analyst'
  | 'Software Designer'
  | 'Marketing & Sales';

export const UserMessageMainRoles: { [key in ROLE_ID]: RoleName } = {
  [ROLE_ID.None]: "We can't find a good match to our roles...",
  [ROLE_ID.Developer]: 'Software Developer',
  [ROLE_ID.SupportAdmin]: 'Support & Admin',
  [ROLE_ID.DataAnalyst]: 'Data Analyst',
  [ROLE_ID.Tester]: 'Software Tester',
  [ROLE_ID.ProjectManager]: 'Project Manager',
  [ROLE_ID.BusinessAnalyst]: 'Business Analyst',
  [ROLE_ID.UXUIDesigner]: 'Software Designer',
  [ROLE_ID.SalesMarketing]: 'Marketing & Sales',
};

export const UserMessageExtraRoles: { [key in EXTRA_ROLES_ROLE_ID]: string } = {
  [EXTRA_ROLES_ROLE_ID.None]: "We don't have any extra roles...",
  [EXTRA_ROLES_ROLE_ID.Engineering]: 'Engineering roles',
};

export enum SUGGESTION_TYPE_ID {
  HasResult = 0,
  HasNoResult = 1,
  HasResultHighR = 2,
  HasNoResultHighR = 3,
}

export const SuggestionMessages: { [key in SUGGESTION_TYPE_ID]: string } = {
  [SUGGESTION_TYPE_ID.HasResult]: `Your answers indicate that you might enjoy the roles below. Of course, life is complex and a quiz can only do so much. This is a starting point and there are many options if these aren’t quite right for you.

  To keep a copy of the suggestions you can email them to yourself.`,
  [SUGGESTION_TYPE_ID.HasNoResult]: `Thanks for doing the quiz. Sometimes we can’t make a clear match to our set of software and tech roles. We’ve done a lot of research but we won’t get it perfect for everyone - we suggest these roles which are excellent places to start.`,
  [SUGGESTION_TYPE_ID.HasResultHighR]: `Your answers match these tech and software roles (which is our focus).  There is an indication you enjoy working with physical, real-world things. If so, also consider roles like engineering or hardware technician. People start in software and move into engineering later or the other way around.`,
  [SUGGESTION_TYPE_ID.HasNoResultHighR]: `Sometimes we can’t make a clear match to our set of software/tech roles. We’ve done a lot of research but we won’t get it perfect for everyone. Your answers indicate you might enjoy making things in the real world - you could consider roles such as electrician, builder, engineer, mechanics, sports, outdoor work. Otherwise, the roles below are good places to start in tech.`,
};

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID ?? 'G-0JP8DKTMN2';
