import React from 'react';
import styles from './RolesIntro.module.scss';
import roleId4Img from '../../../../assets/images/Roles/SoftwareTester/image1.jpg';
import roleId1Img from '../../../../assets/images/Roles/SoftwareDeveloper/image1.jpg';
import roleId7Img from '../../../../assets/images/Roles/SoftwareDesigner/image1.jpg';
import roleId6Img from '../../../../assets/images/Roles/BusinessAnalyst/image1.jpg';
import roleId2Img from '../../../../assets/images/Roles/Support&Admin/image1.jpg';
import roleId8Img from '../../../../assets/images/Roles/MarketingAndSales/image1.jpg';
import roleId5Img from '../../../../assets/images/Roles/ProjectManager/image1.jpg';
import roleId3Img from '../../../../assets/images/Roles/DataAnalyst/image1.jpg';
import CustomBold from '../../../../components/CustomBold/CustomBold';
interface RoleData {
  header: string;
  subHeader: string;
  image: string;
  paragraphs: string[];
}

interface RolesProps {
  roleData: RoleData;
  roleId: number;
}

const RolesIntro: React.FC<RolesProps> = ({ roleData, roleId }) => {
  const roleImgs = [
    { id: 4, img: roleId4Img },
    { id: 1, img: roleId1Img },
    { id: 7, img: roleId7Img },
    { id: 6, img: roleId6Img },
    { id: 2, img: roleId2Img },
    { id: 8, img: roleId8Img },
    { id: 5, img: roleId5Img },
    { id: 3, img: roleId3Img },
  ];
  const roleImg = roleImgs.find((elem) => elem.id === roleId);
  return (
    <div id={styles.foundation}>
      <div id={styles.container}>
        <h1>{roleData.header}</h1>
        <div id={styles.innerContainer}>
          <img src={roleImg?.img} alt={roleData.image} />
          <div id={styles.descriptionContainer}>
            <div>
              <p className={styles.subHeader}>{roleData.subHeader}</p>
              {roleData.paragraphs.map((elem, index) => {
                return (
                  <p key={index}>
                    <CustomBold inputString={elem}></CustomBold>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesIntro;
