import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import ReactGA from 'react-ga4';

import Button from '../../../../components/Button/Button';
import type RootStore from '../../../../stores/RootStore';
import styles from './EmailResult.module.scss';

import EmailResultCopy from '../../../../assets/images/email-result-copy.png';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { sendQuizResultEmail } from '../../../../services/api';

interface EmailResultProps {
  rootStore?: RootStore;
}

const EmailResult: React.FC<EmailResultProps> = observer(({ rootStore }) => {
  const [isInterestedInTechCareers, setIsInterestedInTechCareers] =
    useState(false);
  const [name, setName] = useState('');
  const [region, setRegion] = useState('');
  const [email, setEmail] = useState('');
  const [teacherEmail, setTeacherEmail] = useState('');
  const [ethSelect, setEthSelect] = useState<string[]>([]);
  const [gender, setGender] = useState('');
  const [emailSent, setEmailSent] = useState(false); // State for tracking email status
  const ethnicityOptions = [
    { value: 'Māori', label: 'Māori' },
    { value: 'New Zealand European', label: 'New Zealand European' },
    { value: 'Pasifika', label: 'Pasifika' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'Indian', label: 'Indian' },
    { value: 'Other', label: 'Other' },
    { value: 'Prefer not to say', label: 'Prefer not to say' },
  ];

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [teacherEmailError, setTeacherEmailError] = useState('');
  const [regionError, setRegionError] = useState('');

  const MAX_ETHNICITY_SELECTION = 6;
  const ETHNICITY_SELECTOR_LABEL = `Select all that apply`;

  const validateForm = (): boolean => {
    let isValid = true;

    if (name.trim().length === 0) {
      setNameError('Name is required');
      isValid = false;
    } else {
      setNameError('');
    }

    if (email.trim().length === 0) {
      setEmailError('Email is required');
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError('Invalid email format');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (teacherEmail.trim().length !== 0) {
      if (!/^\S+@\S+\.\S+$/.test(teacherEmail)) {
        setTeacherEmailError('Invalid email format');
        isValid = false;
      } else {
        setTeacherEmailError('');
      }
    } else {
      setTeacherEmailError('');
    }

    if (region.trim().length === 0) {
      setRegionError('Region is required');
      isValid = false;
    } else {
      setRegionError('');
    }
    return isValid;
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsInterestedInTechCareers(event.target.checked);
  };

  const handleEthnicityChange = (event: SelectChangeEvent<string[]>): void => {
    const selectedValues = event.target.value as string[];
    const slicedValues = selectedValues.slice(0, MAX_ETHNICITY_SELECTION);
    setEthSelect(slicedValues);
  };

  const intermediateHandleSendClick = (): void => {
    if (validateForm()) {
      handleSendClick().catch((err) => {
        console.error('Error during sending email:', err);
      });
    }
  };

  const handleSendClick = async (): Promise<void> => {
    console.log(name, region, email, ethSelect, gender, teacherEmail);
    try {
      rootStore?.setLoading(true);
      const formData = {
        name,
        region,
        email,
        teacherEmail,
        ethnicity: ethSelect,
        gender,
        // interestedInTechCareers: isInterestedInTechCareers,
        roleIDs: rootStore?.resultStore.result.mainRoles,
      };

      const { data, error } = await sendQuizResultEmail(formData);

      if (error != null) {
        if (error.includes('Rate limit exceeded')) {
          alert(
            'You have reached the rate limit. Please wait a moment before trying again.',
          );
        } else {
          alert(
            'An error occurred during the quiz evaluation. Please try again later.',
          );
          console.error('Error during quiz evaluation:', error);
        }
        return;
      }

      if (data?.status === 200) {
        console.log('Email sent successfully!');
        setEmailSent(true);
        // Track the event with ReactGA
        ReactGA.event({
          category: 'Result Email',
          action: 'Send',
          label: `Send Email Results - Can contact: ${
            isInterestedInTechCareers ? 'Yes' : 'No'
          }`,
        });
      } else {
        console.error('API returned non-200 status:', data);
      }
    } catch (error: unknown) {
      console.error('An unknown error occurred:', error);
    } finally {
      rootStore?.setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        src={EmailResultCopy}
        alt="Communications Image"
      />
      {!emailSent ? (
        <div className={styles.emailUs}>
          <h3>Enter an email address to get a copy of your results.</h3>
          <div className={styles.contactConfirm}>
            <h3>Would you like more info about tech careers?</h3>
            <input
              type="checkbox"
              name="canContact"
              id="canContact"
              checked={isInterestedInTechCareers}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="canContact"
              className={styles.customCheckbox}
            ></label>
          </div>
          <div className={styles.contactInfo}>
            <div id={styles.inputContainer}>
              <div className={styles.inputBoxs1}>
                <label htmlFor="name-input">
                  <h4>Name*</h4>
                </label>
                <TextField
                  id="name-input"
                  variant="outlined"
                  placeholder="Your name"
                  className={styles.inputFields}
                  InputProps={{
                    sx: {
                      borderRadius: '2.45rem',
                      padding: '0em',
                      '& .MuiInputBase-input': {
                        padding: '0.8em 1em',
                      },
                    },
                  }}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                {nameError !== '' && (
                  <span className={styles.errorMessage}>{nameError}</span>
                )}
              </div>
              <div className={styles.inputBoxs1}>
                <label htmlFor="region-label">
                  <h4>Region*</h4>
                </label>
                <Select
                  labelId="region-select-label"
                  id="region-label"
                  value={region}
                  onChange={(e) => {
                    setRegion(e.target.value);
                  }}
                  className={styles.inputFields}
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '10px', // Adjust padding for the input field
                    },
                    '.MuiSelect-select': {
                      padding: '10px', // Adjust padding for the selected value display
                      fontSize: '0.8em',
                    },
                  }}
                  MenuProps={{
                    sx: {
                      '.MuiMenu-paper': {
                        // Styles for the dropdown menu
                      },
                      '.MuiMenuItem-root': {
                        padding: '5px 10px', // Adjust padding for menu items
                      },
                    },
                  }}
                >
                  <MenuItem value="Northland">Northland</MenuItem>
                  <MenuItem value="Auckland">Auckland</MenuItem>
                  <MenuItem value="Waikato">Waikato</MenuItem>
                  <MenuItem value="Bay of Plenty">Bay of Plenty</MenuItem>
                  <MenuItem value="Gisborne">Gisborne</MenuItem>
                  <MenuItem value="Hawke’s">Hawke’s Bay</MenuItem>
                  <MenuItem value="Taranaki">Taranaki</MenuItem>
                  <MenuItem value="Manawatu-Whanganui">
                    Manawatu-Whanganui
                  </MenuItem>
                  <MenuItem value="Wellington">Wellington</MenuItem>
                  <MenuItem value="Tasman">Tasman</MenuItem>
                  <MenuItem value="Nelson">Nelson</MenuItem>
                  <MenuItem value="Marlborough">Marlborough</MenuItem>
                  <MenuItem value="West Coast">West Coast</MenuItem>
                  <MenuItem value="Otago">Otago</MenuItem>
                  <MenuItem value="Southland">Southland</MenuItem>
                  <MenuItem value="Australia">Australia</MenuItem>
                  <MenuItem value="International">International</MenuItem>
                </Select>
                {regionError !== '' && (
                  <span className={styles.errorMessage}>{regionError}</span>
                )}
              </div>
              <div className={styles.inputBoxs1}>
                <label htmlFor="email-input">
                  <h4>Email*</h4>
                </label>
                <TextField
                  id="email-input"
                  variant="outlined"
                  placeholder="Enter your email"
                  className={styles.inputFields}
                  value={email}
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '2.45rem',
                      padding: '0em',
                      '& .MuiInputBase-input': {
                        padding: '0.8em 1em',
                      },
                    },
                  }}
                />
                {emailError !== '' && (
                  <span className={styles.errorMessage}>{emailError}</span>
                )}
              </div>
              <div className={styles.inputBoxs1}>
                <label htmlFor="ethnicity-select">
                  <h4>Ethnicity</h4>
                </label>
                <FormControl className={styles.FormControlDrop}>
                  <Select
                    id="ethnicity-select"
                    className={styles.inputFields}
                    multiple
                    value={ethSelect}
                    onChange={handleEthnicityChange}
                    aria-label={ETHNICITY_SELECTOR_LABEL}
                    renderValue={(selected) => selected.join(', ')}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '10px', // Adjust padding for the input field
                      },
                      '.MuiSelect-select': {
                        padding: '10px', // Adjust padding for the selected value display
                        fontSize: '0.8em',
                      },
                    }}
                    MenuProps={{
                      sx: {
                        '.MuiMenu-paper': {
                          // Styles for the dropdown menu
                        },
                        '.MuiMenuItem-root': {
                          padding: '5px 10px', // Adjust padding for menu items
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>{ETHNICITY_SELECTOR_LABEL}</em>
                    </MenuItem>
                    {ethnicityOptions.map((elem, index) => (
                      <MenuItem key={index} value={elem.value}>
                        <ListItemIcon>
                          <Checkbox
                            checked={ethSelect.includes(elem.value)}
                          ></Checkbox>
                        </ListItemIcon>
                        <ListItemText primary={elem.label}></ListItemText>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.inputBoxs1}>
                <label htmlFor="gender-select">
                  <h4>Gender</h4>
                </label>
                <Select
                  id="gender-select"
                  value={gender}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setGender(e.target.value);
                  }}
                  className={styles.inputFields}
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '10px', // Adjust padding for the input field
                    },
                    '.MuiSelect-select': {
                      padding: '10px', // Adjust padding for the selected value display
                      fontSize: '0.8em',
                    },
                  }}
                  MenuProps={{
                    sx: {
                      '.MuiMenu-paper': {
                        // Styles for the dropdown menu
                      },
                      '.MuiMenuItem-root': {
                        padding: '5px 10px', // Adjust padding for menu items
                      },
                    },
                  }}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Non-binary">Non-binary</MenuItem>
                  <MenuItem value="Prefer not to say">
                    Prefer not to say
                  </MenuItem>
                </Select>
              </div>
              <div className={styles.inputBoxs1}>
                <label htmlFor="teacher-email-input">
                  <h4>Teacher/Guardian&apos;s Email</h4>
                </label>
                <TextField
                  id="teacher-email-input"
                  variant="outlined"
                  placeholder="Email address to sent a copy of your results"
                  className={styles.inputFields}
                  value={teacherEmail}
                  type="email"
                  onChange={(e) => {
                    setTeacherEmail(e.target.value);
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '2.45rem',
                      padding: '0em',
                      '& .MuiInputBase-input': {
                        padding: '0.8em 1em',
                      },
                    },
                  }}
                />
                {teacherEmailError !== '' && (
                  <span className={styles.errorMessage}>{teacherEmailError}</span>
                )}
              </div>
            </div>
            <div id={styles.buttonSend}>
              <Button type="blue" onClick={intermediateHandleSendClick}>
                Send
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.thankYouMessage}>
          <h2>Thank You</h2>
          <p>A copy of the result has been sent to your email.</p>
        </div>
      )}
    </div>
  );
});

export default inject('rootStore')(EmailResult);
